
interface BottomMenuLinePropsInterface {
    home: boolean
}

export default function BottomMenuLine({home}:BottomMenuLinePropsInterface): React.JSX.Element {
    return (
        <>
        {
            home ?
                <svg className="bottom-menu-line--home-page" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 748 565">
                    <g>
                        <path d="M704.5,79.5c-34.3,1.2-68.5,4.4-102.2,10c-33.7,5.6-66.8,13.6-98.9,24.5c-32.1,10.9-63.2,24.4-92.8,40.4
                            c-29.6,16.1-57.7,34.7-84,55.5c-26.3,20.8-50.8,43.9-73.4,68.6c-22.7,24.7-43.5,51.1-62.6,78.8c-38.3,55.3-69.8,115.3-96.3,177.5
                            l-62.6-28.9c31.8-63.9,69-125.7,113.3-182.3c22.2-28.3,46.1-55.3,72-80.4c25.9-25.1,53.6-48.3,83.1-69
                            c29.5-20.7,60.7-38.8,93.2-54.1c32.5-15.3,66.2-27.6,100.7-37c34.4-9.4,69.5-15.7,104.7-19.5c35.2-3.8,70.6-5,105.8-4.1V79.5z"/>
                    </g>
                    <g>
                        <path d="M-429.6-144.6l20.2-20.9c6.8-6.9,13.8-13.6,20.7-20.4c3.4-3.4,7-6.7,10.6-10l10.7-9.9c1.8-1.6,3.5-3.3,5.3-4.9l5.4-4.8
                            l10.9-9.7c29.2-25.6,59.7-49.8,91.3-72.5c63.2-45.4,131-84.7,202.1-116.8c71.1-32.2,145.6-57.1,222-74.2
                            c38.2-8.5,76.8-15,115.7-19.5c38.9-4.4,78-7,117.1-7.3c78.3-0.8,156.6,6.9,233.2,22.6l14.3,3l7.2,1.5l7.1,1.7l14.3,3.4
                            c2.4,0.6,4.8,1.1,7.1,1.7l7.1,1.9c9.4,2.5,18.9,4.9,28.3,7.8c9.4,2.8,18.8,5.4,28.1,8.5c37.3,11.8,73.8,25.7,109.6,41.3
                            c71.5,31.3,139.7,70,203.2,115c63.5,45.1,122.4,96.2,175.7,152.5l-14.1,14.1c-55.6-52.3-115-100.2-178.5-141.9
                            c-63.4-41.8-130.5-77.7-200.4-106.5c-34.9-14.5-70.6-27.1-106.8-38.1c-9-2.9-18.1-5.3-27.2-7.8c-9.1-2.6-18.3-4.8-27.4-7.1
                            l-6.9-1.7c-2.3-0.6-4.6-1.1-6.9-1.6l-13.8-3.1l-6.9-1.6l-6.9-1.4l-13.9-2.8c-74.1-14.4-149.6-21.4-225.1-20.6
                            c-37.7,0.4-75.4,2.7-112.9,6.9c-37.5,4.2-74.8,10.2-111.7,18.1c-73.9,15.7-146.2,38.9-215.7,68.6
                            c-69.6,29.8-136.4,66.2-199.5,108.4c-31.6,21.1-62.2,43.6-92,67.3l-11.1,9l-5.6,4.5c-1.8,1.5-3.6,3.1-5.5,4.6l-10.9,9.2
                            c-3.6,3.1-7.3,6.1-10.8,9.3c-7.1,6.3-14.3,12.6-21.4,19l-21,19.4L-429.6-144.6z"/>
                    </g>
                    <g>
                        <path className="st0" d="M-124-1412c42.4,0,98-13,132,39c27.2,41.6,3.2,92.3,60,133c74,53,184.8-133,409-31c457,208,145,838-411,505
                            c-110.5,31.5-160.5,31.7-218,0"/>
                        <path d="M-124-1417.9c19.6-0.4,40-3.1,60.9-1.8c10.5,0.7,21.2,2.3,31.5,5.9c5.2,1.8,10.2,4,15,6.8c4.8,2.7,9.3,6,13.5,9.5
                            c4.2,3.5,8,7.6,11.5,11.8l2.6,3.2c0.8,1.1,1.6,2.2,2.4,3.3l1.2,1.7c0.4,0.6,0.7,1.2,1.1,1.7c0.7,1.2,1.5,2.3,2.2,3.6
                            c5.6,9.6,9,20.2,11.4,30.5c2.4,10.3,3.8,20.4,5.7,30c1.9,9.6,4.2,18.7,7.9,27.1c1.8,4.2,4,8.1,6.4,11.9c2.5,3.7,5.3,7.2,8.3,10.6
                            l2.4,2.4c0.4,0.4,0.8,0.8,1.2,1.2l1.3,1.1l2.5,2.3l2.7,2.2c1.7,1.5,3.7,2.8,5.5,4.2l0.7,0.5c0.2,0.2,0.4,0.3,0.7,0.4l1.3,0.8
                            c0.9,0.6,1.8,1,2.6,1.5c7.1,3.8,15.1,5.1,23.6,4.5c8.5-0.6,17.5-3.2,26.4-6.4c8.9-3.3,17.9-7.3,26.9-11.5c9-4.2,18-8.7,27.2-13
                            c18.3-8.8,37.1-17.3,56.7-24.4c19.6-7.1,39.9-12.7,60.7-16.1c20.8-3.4,42.1-4.6,63.3-3.5c10.6,0.6,21.1,1.8,31.6,3.4
                            c2.6,0.4,5.2,0.8,7.8,1.3l7.8,1.5c5.1,1.2,10.3,2.3,15.4,3.7c5.1,1.3,10.2,2.8,15.2,4.3c5,1.6,10,3.2,15,5
                            c9.9,3.5,19.6,7.5,29.2,11.7l14.1,6.6c2.3,1.1,4.7,2.2,7.1,3.3l6.9,3.6l6.9,3.6l3.5,1.8c1.2,0.6,2.3,1.2,3.4,1.9l13.6,7.9
                            c17.9,10.9,35.4,22.6,51.6,36.1l3.1,2.5c1,0.8,2.1,1.6,3,2.5l5.9,5.3l5.9,5.3c1,0.9,2,1.7,2.9,2.7l2.8,2.8
                            c3.8,3.7,7.6,7.4,11.3,11.2l10.7,11.8c14,16.1,26.6,33.4,37.5,51.8c22,36.9,36.2,78.7,41.2,121.8c5.1,43.1,0.8,87.3-12.4,128.8
                            c-13.1,41.5-34.9,80.3-63.6,113.3c-28.6,33-64.1,60-103.1,79.5c-19.5,9.8-39.9,17.8-60.8,23.8c-20.9,6-42.3,10.3-63.8,12.9
                            c-21.5,2.4-43.1,3.2-64.6,2.6l-16.1-0.9c-2.7-0.2-5.4-0.3-8-0.5l-8-0.8l-8-0.8l-4-0.4l-2-0.2l-2-0.3l-15.9-2.4
                            c-5.3-0.7-10.5-1.8-15.8-2.8l-7.8-1.5l-3.9-0.8l-2-0.4l-1.9-0.5l-15.5-3.8c-2.6-0.6-5.2-1.2-7.7-1.9l-7.6-2.2l-15.3-4.4
                            c-5.1-1.6-10.1-3.3-15.1-5l-7.5-2.5l-3.8-1.3l-3.7-1.4l-14.8-5.6c-39.4-15.2-77.2-33.9-113.3-54.9l0,0l26.4,3.5l-13.9,4l-6.9,2
                            l-7,1.9c-4.7,1.2-9.3,2.6-14.1,3.7L19-718.6c-19.2,4.4-39.1,8.3-60.1,10.2c-2.6,0.2-5.2,0.5-7.9,0.7l-8.1,0.3l-4,0.2
                            c-0.7,0-1.3,0.1-2,0.1l-2.1,0l-8.3-0.2c-1.4,0-2.8,0-4.1-0.1l-4.2-0.3l-8.4-0.7c-1.4-0.1-2.8-0.4-4.2-0.6l-4.2-0.6l-4.2-0.7
                            l-2.1-0.3c-0.7-0.1-1.4-0.3-2.1-0.4l-8.4-1.9c-1.4-0.3-2.8-0.6-4.2-1l-4.1-1.2l-8.2-2.5l-7.9-2.9l-3.9-1.5
                            c-1.3-0.5-2.5-1.1-3.8-1.6l-7.6-3.3l-1.9-0.8c-0.6-0.3-1.2-0.6-1.8-0.9l-3.6-1.8l-7.2-3.7c-17.9-9.1-25.1-31-16-48.9
                            s31-25.1,48.9-16c0.5,0.2,0.9,0.5,1.3,0.7l0.8,0.5c14.1,8,27.8,13.7,42,17c0.4,0.1,0.9,0.2,1.3,0.3l1.4,0.2l2.7,0.5l2.7,0.5
                            c0.9,0.1,1.8,0.4,2.7,0.4l5.6,0.6l2.8,0.3c0.9,0.1,1.9,0.1,2.9,0.1l5.8,0.3l1.4,0.1c0.5,0,1,0,1.5,0l3-0.1l6-0.1
                            c2-0.1,4.1-0.3,6.1-0.4c16.3-1.1,33.4-3.9,50.8-7.4l13.1-2.9c4.4-1,8.8-2.1,13.1-3.1l6.6-1.6l6.6-1.7l13.2-3.5l0.5-0.1
                            c9.1-2.4,18.4-0.8,25.9,3.6l0,0c33,20.4,67.3,38.5,102.7,53.4l13.3,5.5l3.3,1.4l3.4,1.2l6.7,2.5c4.5,1.6,9,3.3,13.5,4.9l13.6,4.4
                            l6.8,2.2c2.3,0.7,4.6,1.3,6.9,1.9l13.8,3.8l1.7,0.5l1.7,0.4l3.5,0.8l6.9,1.6c4.6,1,9.3,2.2,13.9,2.9l14,2.5l1.7,0.3l1.8,0.2
                            l3.5,0.5l7,0.9l7,0.9c2.3,0.3,4.7,0.4,7,0.6l14.1,1.2c18.8,1,37.5,0.8,56.2-0.7c18.6-1.7,37-4.9,55-9.5c18-4.5,35.5-10.9,52.2-18.7
                            c33.5-15.6,64.1-37.5,89.1-64.7c25-27.2,44.5-59.6,56.7-94.7c12.2-35,17-72.7,13.7-109.8c-3.3-37.1-14.4-73.7-32.8-106.7
                            c-9.1-16.5-19.9-32.2-32-46.9l-9.3-10.8c-3.3-3.5-6.6-6.9-9.9-10.4l-2.5-2.6c-0.8-0.9-1.7-1.7-2.6-2.5l-5.2-4.9l-5.2-4.9
                            c-0.9-0.8-1.8-1.6-2.7-2.4l-2.8-2.3c-14.4-12.7-30.2-23.9-46.5-34.5l-12.4-7.7c-1-0.7-2.1-1.2-3.2-1.8l-3.2-1.8l-6.4-3.6l-6.4-3.6
                            c-2.1-1.2-4.4-2.2-6.5-3.3l-13.1-6.6c-8.8-4.2-17.6-8.1-26.6-11.5c-4.5-1.8-9-3.3-13.6-5c-4.6-1.5-9.1-3-13.8-4.3
                            c-4.6-1.4-9.3-2.5-13.9-3.7l-7-1.6c-2.3-0.6-4.7-0.9-7-1.4c-9.4-1.8-18.9-3.1-28.4-3.9c-19.1-1.5-38.3-1-57.3,1.5
                            c-19,2.5-37.9,7.2-56.4,13.3c-18.5,6.1-36.7,13.7-55,21.9c-9.2,4.1-18.3,8.3-27.7,12.3c-9.4,4.1-19,8.1-29.1,11.4
                            c-10.1,3.3-20.8,6.2-32.4,6.8c-5.8,0.3-11.8-0.1-17.7-1.4c-5.9-1.3-11.7-3.5-17-6.4c-1.3-0.7-2.6-1.5-3.8-2.3l-1.9-1.3
                            c-0.3-0.2-0.6-0.4-0.9-0.6l-0.8-0.6c-2.1-1.7-4.3-3.3-6.4-5.1l-3.1-2.7l-3-2.9l-1.5-1.4c-0.5-0.5-1-1-1.4-1.5l-2.8-3
                            c-3.6-4.2-7-8.7-10-13.5c-2.9-4.8-5.4-9.8-7.5-14.9c-4.1-10.3-6.5-20.9-8.2-31.1c-1.7-10.2-2.8-20.2-4.7-29.6
                            c-1.9-9.4-4.6-18.3-8.9-26.2c-0.5-1-1.1-2-1.7-2.9c-0.3-0.5-0.6-1-0.9-1.5l-1-1.4l-1.9-2.9l-2.1-2.8c-2.9-3.6-5.9-7.1-9.3-10.1
                            c-3.4-3-7-5.9-10.9-8.2c-3.9-2.4-8-4.4-12.4-6c-8.7-3.3-18-5.1-27.6-6c-9.6-0.8-19.5-0.9-29.4-0.6c-10,0.3-20.1,0.8-30.5,0.7
                            c-3.3,0-5.9-2.7-5.9-6C-129.8-1415.3-127.2-1417.9-124-1417.9L-124-1417.9z"/>
                    </g>
                </svg>
                :
                <svg className="bottom-menu-line--other-pages" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 1751.5 451">
                    <g>
                        <path d="M3293-846.5c-34.3,1.2-68.5,4.4-102.2,10c-33.7,5.6-66.8,13.6-98.9,24.5c-32.1,10.9-63.2,24.4-92.8,40.4
                            c-29.6,16.1-57.7,34.7-84,55.5c-26.3,20.8-50.8,43.9-73.4,68.6c-22.7,24.7-43.5,51.1-62.6,78.8c-38.3,55.3-69.8,115.3-96.3,177.5
                            l-62.6-28.9c31.8-63.9,69-125.7,113.3-182.3c22.2-28.3,46.1-55.3,72-80.4c25.9-25.1,53.6-48.3,83.1-69
                            c29.5-20.7,60.7-38.8,93.2-54.1c32.5-15.3,66.2-27.6,100.7-37c34.4-9.4,69.5-15.7,104.7-19.5c35.2-3.8,70.6-5,105.8-4.1V-846.5z"/>
                    </g>
                    <g>
                        <path d="M41.9,409.4l20.2-20.9c6.8-6.9,13.8-13.6,20.7-20.4c3.4-3.4,7-6.7,10.6-10l10.7-9.9c1.8-1.6,3.5-3.3,5.3-4.9l5.4-4.8
                            l10.9-9.7c29.2-25.6,59.7-49.8,91.3-72.5c63.2-45.4,131-84.7,202.1-116.8c71.1-32.2,145.6-57.1,222-74.2
                            c38.2-8.5,76.8-15,115.7-19.5c38.9-4.4,78-7,117.1-7.3c78.3-0.8,156.6,6.9,233.2,22.6l14.3,3l7.2,1.5l7.1,1.7l14.3,3.4
                            c2.4,0.6,4.8,1.1,7.1,1.7l7.1,1.9c9.4,2.5,18.9,4.9,28.3,7.8c9.4,2.8,18.8,5.4,28.1,8.5c37.3,11.8,73.8,25.7,109.6,41.3
                            c71.5,31.3,139.7,70,203.2,115c63.5,45.1,122.4,96.2,175.7,152.5l-14.1,14.1c-55.6-52.3-115-100.2-178.5-141.9
                            c-63.4-41.8-130.5-77.7-200.4-106.5c-34.9-14.5-70.6-27.1-106.8-38.1c-9-2.9-18.1-5.3-27.2-7.8c-9.1-2.6-18.3-4.8-27.4-7.1
                            l-6.9-1.7c-2.3-0.6-4.6-1.1-6.9-1.6l-13.8-3.1l-6.9-1.6l-6.9-1.4l-13.9-2.8c-74.1-14.4-149.6-21.4-225.1-20.6
                            c-37.7,0.4-75.4,2.7-112.9,6.9c-37.5,4.2-74.8,10.2-111.7,18.1c-73.9,15.7-146.2,38.9-215.7,68.6
                            c-69.6,29.8-136.4,66.2-199.5,108.4c-31.6,21.1-62.2,43.6-92,67.3l-11.1,9l-5.6,4.5c-1.8,1.5-3.6,3.1-5.5,4.6l-10.9,9.2
                            c-3.6,3.1-7.3,6.1-10.8,9.3c-7.1,6.3-14.3,12.6-21.4,19l-21,19.4L41.9,409.4z"/>
                    </g>
                    <g>
                        <path d="M445.5-1185.9c19.6-0.4,40-3.1,60.9-1.8c10.5,0.7,21.2,2.3,31.5,5.9c5.2,1.8,10.2,4,15,6.8c4.8,2.7,9.3,6,13.5,9.5
                            c4.2,3.5,8,7.6,11.5,11.8l2.6,3.2c0.8,1.1,1.6,2.2,2.4,3.3l1.2,1.7c0.4,0.6,0.7,1.2,1.1,1.7c0.7,1.2,1.5,2.3,2.2,3.6
                            c5.6,9.6,9,20.2,11.4,30.5c2.4,10.3,3.8,20.4,5.7,30c1.9,9.6,4.2,18.7,7.9,27.1c1.8,4.2,4,8.1,6.4,11.9c2.5,3.7,5.3,7.2,8.3,10.6
                            l2.4,2.4c0.4,0.4,0.8,0.8,1.2,1.2l1.3,1.1l2.5,2.3l2.7,2.2c1.7,1.5,3.7,2.8,5.5,4.2l0.7,0.5c0.2,0.2,0.4,0.3,0.7,0.4l1.3,0.8
                            c0.9,0.6,1.8,1,2.6,1.5c7.1,3.8,15.1,5.1,23.6,4.5c8.5-0.6,17.5-3.2,26.4-6.4c8.9-3.3,17.9-7.3,26.9-11.5c9-4.2,18-8.7,27.2-13
                            c18.3-8.8,37.1-17.3,56.7-24.4c19.6-7.1,39.9-12.7,60.7-16.1c20.8-3.4,42.1-4.6,63.3-3.5c10.6,0.6,21.1,1.8,31.6,3.4
                            c2.6,0.4,5.2,0.8,7.8,1.3l7.8,1.5c5.1,1.2,10.3,2.3,15.4,3.7c5.1,1.3,10.2,2.8,15.2,4.3c5,1.6,10,3.2,15,5
                            c9.9,3.5,19.6,7.5,29.2,11.7l14.1,6.6c2.3,1.1,4.7,2.2,7.1,3.3l6.9,3.6l6.9,3.6l3.5,1.8c1.2,0.6,2.3,1.2,3.4,1.9l13.6,7.9
                            c17.9,10.9,35.4,22.6,51.6,36.1l3.1,2.5c1,0.8,2.1,1.6,3,2.5l5.9,5.3l5.9,5.3c1,0.9,2,1.7,2.9,2.7l2.8,2.8
                            c3.8,3.7,7.6,7.4,11.3,11.2l10.7,11.8c14,16.1,26.6,33.4,37.5,51.8c22,36.9,36.2,78.7,41.2,121.8c5.1,43.1,0.8,87.3-12.4,128.8
                            c-13.1,41.5-34.9,80.3-63.6,113.3c-28.6,33-64.1,60-103.1,79.5c-19.5,9.8-39.9,17.8-60.8,23.8c-20.9,6-42.3,10.3-63.8,12.9
                            c-21.5,2.4-43.1,3.2-64.6,2.6l-16.1-0.9c-2.7-0.2-5.4-0.3-8-0.5l-8-0.8l-8-0.8l-4-0.4l-2-0.2l-2-0.3l-15.9-2.4
                            c-5.3-0.7-10.5-1.8-15.8-2.8l-7.8-1.5l-3.9-0.8l-2-0.4l-1.9-0.5l-15.5-3.8c-2.6-0.6-5.2-1.2-7.7-1.9l-7.6-2.2l-15.3-4.4
                            c-5.1-1.6-10.1-3.3-15.1-5l-7.5-2.5l-3.8-1.3l-3.7-1.4l-14.8-5.6c-39.4-15.2-77.2-33.9-113.3-54.9l0,0l26.4,3.5l-13.9,4l-6.9,2
                            l-7,1.9c-4.7,1.2-9.3,2.6-14.1,3.7l-14.3,3.5c-19.2,4.4-39.1,8.3-60.1,10.2c-2.6,0.2-5.2,0.5-7.9,0.7l-8.1,0.3l-4,0.2
                            c-0.7,0-1.3,0.1-2,0.1l-2.1,0l-8.3-0.2c-1.4,0-2.8,0-4.1-0.1l-4.2-0.3l-8.4-0.7c-1.4-0.1-2.8-0.4-4.2-0.6l-4.2-0.6l-4.2-0.7
                            l-2.1-0.3c-0.7-0.1-1.4-0.3-2.1-0.4l-8.4-1.9c-1.4-0.3-2.8-0.6-4.2-1l-4.1-1.2l-8.2-2.5l-7.9-2.9l-3.9-1.5
                            c-1.3-0.5-2.5-1.1-3.8-1.6l-7.6-3.3l-1.9-0.8c-0.6-0.3-1.2-0.6-1.8-0.9l-3.6-1.8l-7.2-3.7c-17.9-9.1-25.1-31-16-48.9
                            s31-25.1,48.9-16c0.5,0.2,0.9,0.5,1.3,0.7l0.8,0.5c14.1,8,27.8,13.7,42,17c0.4,0.1,0.9,0.2,1.3,0.3l1.4,0.2l2.7,0.5l2.7,0.5
                            c0.9,0.1,1.8,0.4,2.7,0.4l5.6,0.6l2.8,0.3c0.9,0.1,1.9,0.1,2.9,0.1l5.8,0.3l1.4,0.1c0.5,0,1,0,1.5,0l3-0.1l6-0.1
                            c2-0.1,4.1-0.3,6.1-0.4c16.3-1.1,33.4-3.9,50.8-7.4l13.1-2.9c4.4-1,8.8-2.1,13.1-3.1l6.6-1.6l6.6-1.7l13.2-3.5l0.5-0.1
                            c9.1-2.4,18.4-0.8,25.9,3.6l0,0c33,20.4,67.3,38.5,102.7,53.4l13.3,5.5l3.3,1.4l3.4,1.2l6.7,2.5c4.5,1.6,9,3.3,13.5,4.9l13.6,4.4
                            l6.8,2.2c2.3,0.7,4.6,1.3,6.9,1.9l13.8,3.8l1.7,0.5l1.7,0.4l3.5,0.8l6.9,1.6c4.6,1,9.3,2.2,13.9,2.9l14,2.5l1.7,0.3l1.8,0.2
                            l3.5,0.5l7,0.9l7,0.9c2.3,0.3,4.7,0.4,7,0.6l14.1,1.2c18.8,1,37.5,0.8,56.2-0.7c18.6-1.7,37-4.9,55-9.5c18-4.5,35.5-10.9,52.2-18.7
                            c33.5-15.6,64.1-37.5,89.1-64.7c25-27.2,44.5-59.6,56.7-94.7c12.2-35,17-72.7,13.7-109.8c-3.3-37.1-14.4-73.7-32.8-106.7
                            c-9.1-16.5-19.9-32.2-32-46.9l-9.3-10.8c-3.3-3.5-6.6-6.9-9.9-10.4l-2.5-2.6c-0.8-0.9-1.7-1.7-2.6-2.5l-5.2-4.9l-5.2-4.9
                            c-0.9-0.8-1.8-1.6-2.7-2.4l-2.8-2.3c-14.4-12.7-30.2-23.9-46.5-34.5l-12.4-7.7c-1-0.7-2.1-1.2-3.2-1.8l-3.2-1.8l-6.4-3.6l-6.4-3.6
                            c-2.1-1.2-4.4-2.2-6.5-3.3l-13.1-6.6c-8.8-4.2-17.6-8.1-26.6-11.5c-4.5-1.8-9-3.3-13.6-5c-4.6-1.5-9.1-3-13.8-4.3
                            c-4.6-1.4-9.3-2.5-13.9-3.7l-7-1.6c-2.3-0.6-4.7-0.9-7-1.4c-9.4-1.8-18.9-3.1-28.4-3.9c-19.1-1.5-38.3-1-57.3,1.5
                            c-19,2.5-37.9,7.2-56.4,13.3c-18.5,6.1-36.7,13.7-55,21.9c-9.2,4.1-18.3,8.3-27.7,12.3c-9.4,4.1-19,8.1-29.1,11.4
                            c-10.1,3.3-20.8,6.2-32.4,6.8c-5.8,0.3-11.8-0.1-17.7-1.4c-5.9-1.3-11.7-3.5-17-6.4c-1.3-0.7-2.6-1.5-3.8-2.3l-1.9-1.3
                            c-0.3-0.2-0.6-0.4-0.9-0.6l-0.8-0.6c-2.1-1.7-4.3-3.3-6.4-5.1l-3.1-2.7l-3-2.9l-1.5-1.4c-0.5-0.5-1-1-1.4-1.5l-2.8-3
                            c-3.6-4.2-7-8.7-10-13.5c-2.9-4.8-5.4-9.8-7.5-14.9c-4.1-10.3-6.5-20.9-8.2-31.1c-1.7-10.2-2.8-20.2-4.7-29.6
                            c-1.9-9.4-4.6-18.3-8.9-26.2c-0.5-1-1.1-2-1.7-2.9c-0.3-0.5-0.6-1-0.9-1.5l-1-1.4l-1.9-2.9l-2.1-2.8c-2.9-3.6-5.9-7.1-9.3-10.1
                            c-3.4-3-7-5.9-10.9-8.2c-3.9-2.4-8-4.4-12.4-6c-8.7-3.3-18-5.1-27.6-6c-9.6-0.8-19.5-0.9-29.4-0.6c-10,0.3-20.1,0.8-30.5,0.7
                            c-3.3,0-5.9-2.7-5.9-6C439.7-1183.3,442.3-1185.9,445.5-1185.9L445.5-1185.9z"/>
                    </g>
                </svg>
        }
        </>
    )
}