import { useState } from "react";

interface VolumeButtonPropsInterface {
    volume: number,
    setVolume: (volume: number)=> void,
    setDisplay: (display: boolean)=> void
}

export default function VolumeButton({volume,setVolume,setDisplay}: VolumeButtonPropsInterface): React.JSX.Element {
    
    const [savedVolume,setSavedVolume] = useState<number>(0);

    const onClick = ()=> {
        if(volume === 0) {
            setVolume(savedVolume);
        } else {
            setSavedVolume(volume);
            setVolume(0);
        };
    };
    
    return (
        <button className="volume-button video-player-button" aria-label={"Volume"}
            onMouseOver={()=> setDisplay(true)}
            onMouseLeave={()=> setDisplay(false)}
            onClick={()=> onClick()}>
                {
                    volume === 0 &&
                        <svg className="volume-button_icon volume-button_icon--muted" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 127.6 103.3">
                            <line className="cross" x1="-550.6" y1="-429.7" x2="-494.1" y2="-373.2"/>
                            <line className="cross" x1="-494.1" y1="-429.7" x2="-550.6" y2="-373.2"/>
                            <line className="cross" x1="15" y1="36.5" x2="43.2" y2="64.7"/>
                            <line className="cross" x1="43.2" y1="36.5" x2="15" y2="64.7"/>
                            <line className="cross" x1="-279.9" y1="-318.2" x2="-317" y2="-281.1"/>
                            <line className="cross" x1="-279.3" y1="-317.5" x2="-242.2" y2="-280.5"/>
                            <path d="M-132.9-226.8l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4l-29.4,22.2c-4.7,3.5-11.3,0.2-11.3-5.6v-77.3C-144.5-227.3-137.5-230.6-132.9-226.8z"/>
                            <path d="M92.5-272.5l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4l-29.4,22.2c-4.7,3.5-11.3,0.2-11.3-5.6V-267C80.9-273,87.9-276.3,92.5-272.5z"/>
                            <path d="M69,6.5l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4L68.8,94.9c-4.7,3.5-11.3,0.2-11.3-5.6V12C57.4,6,64.4,2.7,69,6.5z"/>
                            <path d="M69-148.3l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4L68.8-59.9c-4.7,3.5-11.3,0.2-11.3-5.6v-77.3C57.4-148.8,64.4-152.1,69-148.3z"/>
                        </svg>
                }   
                {
                    volume <= 0.5 && volume !== 0 &&
                        <svg className="volume-button_icon volume-button_icon--low" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 109 99.1">
                            <path d="M-337.4,93.5l-8.4-8.4c-1.8-1.8-1.8-4.6,0-6.4l8.4-8.4c1.8-1.8,4.6-1.8,6.4,0l8.4,8.4c1.8,1.8,1.8,4.6,0,6.4l-8.4,8.4
                                C-332.7,95.3-335.6,95.3-337.4,93.5z"/>
                            <path d="M-401.2,157.3l-8.4-8.4c-1.8-1.8-1.8-4.6,0-6.4l8.4-8.4c1.8-1.8,4.6-1.8,6.4,0l8.4,8.4c1.8,1.8,1.8,4.6,0,6.4l-8.4,8.4
                                C-396.5,159-399.4,159-401.2,157.3z"/>
                            <path d="M-404.6,122.7h-11.8c-2.5,0-4.5-2-4.5-4.5v-11.8c0-2.5,2-4.5,4.5-4.5h11.8c2.5,0,4.5,2,4.5,4.5v11.8
                                C-400,120.6-402.1,122.7-404.6,122.7z"/>
                            <path d="M-151.4-72.8l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1H-118
                                c-1.5,0-3,0.5-4.3,1.4l-29.4,22.2c-4.7,3.5-11.3,0.2-11.3-5.6v-77.3C-163-73.4-156-76.7-151.4-72.8z"/>
                            <path d="M74-118.6l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4L73.7-30.2c-4.7,3.5-11.3,0.2-11.3-5.6v-77.3C62.4-119.1,69.4-122.4,74-118.6z"/>
                            <path d="M50.5,5.6L78,28.4c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1H83.9
                                c-1.5,0-3,0.5-4.3,1.4L50.2,94c-4.7,3.5-11.3,0.2-11.3-5.6V11.1C38.9,5.1,45.9,1.8,50.5,5.6z"/>
                            <path className="st3" d="M-174.3-44.7c-17.6,4.5-17.6,28.7,0,35.6"/>
                            <path className="st3" d="M23.8,32.5c-17.6,4.5-17.6,28.7,0,35.6"/>
                            <path className="st3" d="M-177-64.3c-43,10.5-44.2,64.5,0,75"/>
                        </svg>
                }
                {
                    volume > 0.5 &&
                        <svg className="volume-button_icon volume-button_icon--high" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 128.3 108.3">
                            <path d="M-116.2,175.4l-8.4-8.4c-1.8-1.8-1.8-4.6,0-6.4l8.4-8.4c1.8-1.8,4.6-1.8,6.4,0l8.4,8.4c1.8,1.8,1.8,4.6,0,6.4l-8.4,8.4
                                C-111.5,177.2-114.4,177.2-116.2,175.4z"/>
                            <path d="M-179.9,239.2l-8.4-8.4c-1.8-1.8-1.8-4.6,0-6.4l8.4-8.4c1.8-1.8,4.6-1.8,6.4,0l8.4,8.4c1.8,1.8,1.8,4.6,0,6.4l-8.4,8.4
                                C-175.3,240.9-178.2,240.9-179.9,239.2z"/>
                            <path d="M-183.4,204.6h-11.8c-2.5,0-4.5-2-4.5-4.5v-11.8c0-2.5,2-4.5,4.5-4.5h11.8c2.5,0,4.5,2,4.5,4.5v11.8
                                C-178.8,202.6-180.9,204.6-183.4,204.6z"/>
                            <path d="M69.8,9.1l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6H114c3.9,0,7.1,3.2,7.1,7.1v26.2c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4L69.5,97.5c-4.7,3.5-11.3,0.2-11.3-5.6V14.5C58.2,8.5,65.2,5.3,69.8,9.1z"/>
                            <path d="M295.2-36.6l27.5,22.8c1.3,1.1,2.9,1.6,4.5,1.6h12.2c3.9,0,7.1,3.2,7.1,7.1V21c0,3.9-3.2,7.1-7.1,7.1h-10.8
                                c-1.5,0-3,0.5-4.3,1.4l-29.4,22.2c-4.7,3.5-11.3,0.2-11.3-5.6v-77.3C283.6-37.2,290.6-40.5,295.2-36.6z"/>
                            <path className="high" d="M46.9,37.3c-17.6,4.5-17.6,28.7,0,35.6"/>
                            <path className="high" d="M44.2,17.6C1.2,28,0,82,44.2,92.6"/>
                        </svg>
                }
        </button>
    )
}