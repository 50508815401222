
export const colorsRadioButtonsData = [
    {
        id: 0,
        name: "blue",
        frenchLabel: "bleu",
        englishLabel: "blue"
    },
    {
        id: 1,
        name: "purple",
        frenchLabel: "violet",
        englishLabel: "purple"
    }
]