
export const fontsRadioButtonsData = [
    {
        id: 0,
        name: "default",
        frenchLabel: "Défault",
        englishLabel: "Default"
    },
    {
        id: 1,
        name: "open-dyslexic",
        frenchLabel: "Open Dyslexic",
        englishLabel: "Open Dyslexic"
    }
]